import HeaderText from "../../components/header/headerText";
import Loading from "../../components/loading/loading";
export default function HistoryPayment() {





    

    // useEffect(() => {
    //   liff.init({ liffId: '2006354132-Rpne3eZD', withLoginOnExternalBrowser: true })
    //     .then(async () => {
    //       if (liff.isLoggedIn()) {
    //         return true
    //       } else {
    //         liff.login();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    // }, []);

 



    return (
        <div>
            <div className="container pt-3 px-3 py-2">
                <div className="mb-4">
                    <HeaderText label="ประวัติการชำระ"  enableBorder={true} />
                </div>
            </div>
            <div className="container pt-3 px-2 py-2"  style={{ width: '300px', height: '100px', padding: '50px', border: '1px solid red' }}>
                <div className="mb-4">
                    {/* {date || "20/09/2567"} */}
                   <span>วันที่ชำระ: 20/09/2567 </span>
                   <span>สถานะ: รอตรวจสอบ </span>
                   <span>4000 บาท </span>
                </div>
                bu
            </div>
        </div>

    );


}
