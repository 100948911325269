import liff from "@line/liff";
import Download from '@mui/icons-material/FileDownloadOutlined';
import { Box, LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import HeaderText from "../../../components/header/headerText";
import InputTextField from "../../../components/input/inputTextField";
import Loading from "../../../components/loading/loading";
import { swalError } from "../../../components/notification/swal";
import { colors } from "../../../constants/colors";
import { onImgError } from "../../../constants/images";
import { idCardFormat, numberFormat } from "../../../utils/common";
import { decryptString } from "../../../utils/crypto-js";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import _ from "lodash";

const ImageStyle = styled(`img`)(({ theme }) => ({
    width: 180,
    height: 180,

    [theme.breakpoints.down("lg")]: {
        width: 170,
        height: 170,
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: colors.disabledLightGray
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: colors.themeMainColor,
    },
}));


export default function AomdownDetail() {
    const [data, setData] = useState<any>();
    const [loadingPage, setLoadingPage] = useState(false);
    const [selectOptionDownPayment, setSelectOptionDownPayment] = useState<string>('')
    const [optionDownPaymentNo, setOptionDownPaymentNo] = useState<any[]>([])
    const [step, setStep] = useState<Boolean>(false)
    const [typeAomDownPayment, setTypeAomDown] = useState<string>('')

    const formatRoundPayment = (key: string) => {
        switch (key) {
            case 'DAY':
                return 'รายวัน'
            case 'WEEK':
                return 'รายสัปดาห์'
            case 'MONTH':
                return 'รายเดือน'
            default:
                break;
        }
    }
    const getDownPaymentNo = async () => {
        try {
            const result = await AomdownApi.getDownPaymentNo();
            if (result.status === 200) {
                setOptionDownPaymentNo(result.data.data);
            }
        } catch (error) {
            swalError('Option DownPaymentNo ' + error);
        }
    };
    useEffect(() => {
        getDownPaymentNo()
    }, []);
    useEffect(() => {
        if (selectOptionDownPayment) {
            getAomdownDetail()
        }

    }, [selectOptionDownPayment])

    useEffect(() => {
        liff.init({ liffId: '2006354132-yODe7e1b' })
    }, []);
    const getAomdownFileDownload = async (downPaymentNo: string) => {
        try {
            setLoadingPage(true);
            const res = await AomdownApi.getAomdownPDF(downPaymentNo)
            if (res.status == 200) {
                liff.openWindow(
                    {
                        url: res.data.data,
                        external: true
                    }
                )
            }
            setLoadingPage(false);
        } catch (error) {
            setLoadingPage(false);
            console.error('Error downloading file:', error);
        }
    };

    const getAomdownDetail = async () => {
        try {
            setLoadingPage(true)
          
            // PRD2024101019
            const res = await AomdownApi.getAomdownDetail(selectOptionDownPayment);
            if (res.status == 200) {
                setStep(true)
                const aomdownDetail = res?.data?.data
                if (aomdownDetail) {
                    const product_down = aomdownDetail?.product_down_payment?.product
                    const data = {
                        downpayment_no: decryptString(aomdownDetail?.downpayment_no),
                        register_date: aomdownDetail?.created_date,
                        name: decryptString(aomdownDetail?.user_info?.general?.fullname),
                        idCard: decryptString(aomdownDetail?.id_card),
                        product_name: product_down?.text,
                        aomdown_price: aomdownDetail?.amount,
                        round_payment: aomdownDetail?.round_payment,
                        time_payment: aomdownDetail?.time_payment,
                        round_amount: aomdownDetail?.round_amount,
                        pdfContract: aomdownDetail?.pdfContract,
                        paid_amount: aomdownDetail?.paid_amount,
                        paid_rate: aomdownDetail?.paid_rate,
                        img: aomdownDetail?.product_img
                    }
                    setData(data)
                }
            } else {
                setStep(true)
                swalError(String(res?.data?.message));
            }
            setLoadingPage(false)
        } catch (error: any) {
            setLoadingPage(false)
            swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
            console.error(error)
        }
    }

    return (
        <div className="fw-bold px-3 py-3">
            <Loading show={loadingPage} />

            <div className="pt-3 fw-bold">
                <HeaderText label="ข้อมูลสัญญา" enableBorder={true} />
            </div>

            <div className="pt-4">
                <AutocompleteSelect
                    key={"optionDownPayment"}
                    size="medium"
                    options={optionDownPaymentNo}
                    value={_.find(optionDownPaymentNo, (val: any) => val.downpayment_no === selectOptionDownPayment) || null}
                    getOptionLabel={(option: any) => option?.value || ''}
                    labelId="optionDownPayment"
                    onChange={(event, value, reason) => {
                        if (value && value.downpayment_no) {
                            setSelectOptionDownPayment(value.downpayment_no);
                            setTypeAomDown(value.type_aomdown)
                        } else if (reason === 'clear') {
                            setSelectOptionDownPayment('');
                            setStep(false)
                        }
                    }}
                    heading="เลือกสัญญา"
                    placeholder="เลือกสัญญา"
                />
            </div>

            {step === true && (
                <>
                    <div>
                        <Row className="pt-3 fw-bold">
                            <Col>
                                <InputTextField
                                    disabled
                                    value={data?.register_date}
                                    heading={typeAomDownPayment === 'INSTALLMENT_AND_USE' ? 'วันที่ลงทะเบียน' : 'วันที่ทำสัญญา'}
                                />
                            </Col>
                            <Col>
                                <div>{typeAomDownPayment === 'INSTALLMENT_AND_USE' ? 'สัญญาออมดาวน์' : 'สัญญาผ่อนสินค้า'}</div>
                                <ButtonCustom
                                    mode={'default'}
                                    btnStyle={{ width: '100%', height: 40, borderRadius: 8 }}
                                    endIcon={<Download />}
                                    textButton={'ดาวน์โหลด'}
                                    onClick={() => {
                                        if (data?.downpayment_no) getAomdownFileDownload(data?.downpayment_no);
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>

                    <Box>
                        {/* { typeAomDownPayment=== 'INSTALLMENT_AND_USE' ? 
              <div className="pt-1">
                  <InputTextField
                    style={{ marginTop: '10px' }}
                    disabled
                    value={`${data?.name || ''}`}
                    heading="ชื่อ-นามสกุล"
                  />
                </div> : ''}
                 */}
                        {/* <div className="pt-1">
                  <InputTextField
                    style={{ marginTop: '10px' }}
                    disabled
                    value={idCardFormat(data?.idCard || '') || ''}
                    heading="เลขบัตรประชาชน"
                  />
                </div> */}
                        <div className="pt-1">
                            <InputTextField
                                style={{ marginTop: '10px' }}
                                disabled
                                value={data?.product_name || ''}
                                heading="สินค้า"
                            />
                        </div>
                        <div className="pt-1">
                            <Row>
                                <Col>
                                    <InputTextField
                                        style={{ marginTop: '10px' }}
                                        disabled
                                        value={formatRoundPayment(data?.round_payment)}
                                        // {typeAomDownPayment === 'INSTALLMENT_AND_USE' ? formatRoundPayment(data?.round_payment) : data?.round_amount}
                                        heading='รอบการออม'
                                    // {typeAomDownPayment === 'INSTALLMENT_AND_USE' ? 'รอบการออม' : 'ยอดผ่อนต่องวด'}
                                    />
                                </Col>
                                <Col>
                                    <InputTextField
                                        style={{ marginTop: '10px' }}
                                        disabled
                                        value={data?.time_payment || ''}
                                        heading='ระยะเวลาออม (เดือน)'
                                    // {typeAomDownPayment === 'INSTALLMENT_AND_USE' ? 'ระยะเวลาออม (เดือน)' : 'งวดทั้งหมด'}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="pt-1 fw-bold">
                            <InputTextField
                                style={{ marginTop: '10px' }}
                                disabled
                                value={numberFormat(data?.round_amount, 0, 0)}
                                heading="ยอดออมต่อรอบ"
                            />
                            {/* {typeAomDownPayment === 'INSTALLMENT_AND_USE' ?
                                <InputTextField
                                    style={{ marginTop: '10px' }}
                                    disabled
                                    value={numberFormat(data?.round_amount, 0, 0)}
                                    heading="ยอดออมต่อรอบ"
                                /> : ''
                            } */}

                        </div>
                        <div className="pt-2">
                            <div className="pb-2 d-flex justify-content-between">
                                <div>ชำระแล้ว</div>
                                {/* {typeAomDownPayment === 'INSTALLMENT_AND_USE' ? 'ยอดออม' : 'ยอดผ่อน'} */}
                                <div>ยอดออม</div>
                            </div>

                            <BorderLinearProgress variant="determinate" value={data?.paid_rate || 0} />
                            <div className="pt-1 d-flex justify-content-between">
                                <div>{numberFormat(data?.paid_amount, 0, 0)}</div>
                                <div>{numberFormat(data?.aomdown_price, 0, 0)}</div>
                            </div>
                            <div className="fw-bold d-flex justify-content-center pt-3 font-20 " style={{ color: colors.themeMainColor }} >
                                {/* data?.paid_amount >= data?.aomdown_price */}
                                {/* {typeAomDownPayment === 'INSTALLMENT_AND_USE' && data?.paid_amount >= data?.aomdown_price ? (
                                    'ออมครบแล้ว รับเครื่องได้เลย'
                                ) : typeAomDownPayment === 'PAY_THEN_RECEIVE' && data?.paid_amount >= data?.aomdown_price ? (
                                    'ปิดสัญญาเรียบร้อย'
                                ) : null} */}
                                {data?.paid_amount >= data?.aomdown_price? (
                                    'ออมครบแล้ว รับเครื่องได้เลย'
                                )  : null}
                            </div>
                        </div>

                        {data?.paid_amount >= data?.aomdown_price? (
                            ''
                        ) : (
                            <div className="text-center pt-4">
                                <ImageStyle className="" src={data?.img} onError={onImgError} />
                            </div>
                        )}



                    </Box>
                </>
            )}
        </div>
    );

}