import liff from "@line/liff";
import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import AuthenApi from "../../../api/authen/authen.api";
import StorageUploadApi from "../../../api/storage/storage.api";
import Sign from "../../../assets/icon/sign";
import ButtonCustom from "../../../components/button/buttonCustom";
import Loading from "../../../components/loading/loading";
import { swalError, swalSuccess } from "../../../components/notification/swal";
import { colors } from "../../../constants/colors";

interface props {
    accTokenLine: string
}
export default function SignContact(props: props) {
    const singpad = useRef<SignatureCanvas>(null);
    const [signatureDataURL, setSignatureDataURL] = useState<any>();
    const [loadingPage, setLoadingPage] = useState(false);

    const dataURItoBlob = (dataURI: string) => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        const file = new File([blob], "SignContact.png", { type: mimeString });
        return file; // คืนค่าที่เป็นไฟล์แทน blob

    };

    const handleEnd = () => {
        if (singpad.current) {
            const dataUrl = singpad.current.toDataURL(); // Get base64 data URL
            const data = dataURItoBlob(dataUrl);

            setSignatureDataURL(data);
        }
    };

    const closePage = async () => {
        await AuthenApi.verify(props.accTokenLine || '')
        liff.closeWindow();
    }

    const uploadImg = async (signatureBlob: Blob) => {
        if (signatureBlob) {
            // สร้าง FormData
            const formData = new FormData();
            formData.append('image', signatureBlob);
            formData.append('bucket', "1");

            try {
                // เรียกใช้ API อัปโหลดไฟล์
                setLoadingPage(true);
                const responseFileUpload = await StorageUploadApi.uploadFile(formData);

                if (responseFileUpload.status == 200) {
                    const urlImageSign = responseFileUpload?.data?.url[0]?.url
                    const payload = {
                        urlSignature: urlImageSign,
                        accessTokenLine: props.accTokenLine || ''
                    }
                    const responseSignature = await AomdownApi.uploadSignature(payload);
                    if (responseSignature.status == 200) {
                        swalSuccess(
                            "ลงทะเบียนออมดาวน์สำเร็จ",
                            '',
                            '',
                            null,
                            closePage
                        );
                    }
                }
                setLoadingPage(false);
            } catch (error) {
                swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
                setLoadingPage(false);
                console.error('Upload failed:', error);
            }
        } else {
            setLoadingPage(false);
        }
    }


    const handleClear = () => {
        if (singpad.current) {
            singpad.current.clear();
            setSignatureDataURL("");
        }
    };

    return (
        <div className="px-3 py-3">
            <Loading show={loadingPage} />
            <div >
                <p className="font-bold font-16" > ลงลายเซ็นต์   <Sign></Sign></p>
                <p className="fw-300 font-14" style={{ color: colors.darkGray }}>
                    กรุณาลงลายมือชื่อ โดยใช้นิ้ววาดลงในพื้นที่ด้านล่าง
                </p>
            </div>

            <div className="pt-1"
                style={{
                    display: "flex",
                    width: "100%",
                    height: "385px",
                    backgroundColor: colors.bgModal,
                    borderRadius: 8
                }}>
                <SignatureCanvas
                    canvasProps={{ className: "sigCanvas" }}
                    ref={singpad}
                    onEnd={handleEnd}
                />
            </div>
            <div className="pt-3">
                <ButtonCustom
                    mode={'default'}
                    btnStyle={{ width: '100%' }}
                    textButton={'ยืนยัน'}
                    disabled={!signatureDataURL}
                    onClick={() => {
                        uploadImg(signatureDataURL);
                        handleClear()
                    }}
                />
            </div>
            <div className="pt-3">
                <ButtonCustom
                    btnStyle={{ width: '100%' }}
                    variant="outlined"
                    disabled={!signatureDataURL}
                    textButton={'ล้างลายเซ็นต์'}
                    onClick={() => {
                        handleClear()
                    }}
                />
            </div>
        </div>
    )
}