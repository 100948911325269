export const routeName = {
  register: '/register',
  registerAomDown: '/register-aom-down',
  aomDownDetail: '/aom-down-detail',
  paySlip: '/payslip',
  login: '/login',
  logout: '/logout',
  history:'/history',
  personalInfomation: '/personal-infomation'
};
