import {
    Typography
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import OtpApi from "../../api/otp/otp.api";
import LockIcon from "../../assets/icon/lock";
import PhoneIcon from "../../assets/icon/phone";
import ButtonCustom from "../../components/button/buttonCustom";
import HeaderText from "../../components/header/headerText";
import InputTextField from "../../components/input/inputTextField";
import Loading from "../../components/loading/loading";
import {
    swalError
} from "../../components/notification/swal";
import { colors } from "../../constants/colors";
import { checkPattenPhoneNumber, phoneNumberFormat } from "../../utils/common";
import { encryptString } from '../../utils/crypto-js';
import { InputAdornment } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
interface otp {
    register?: boolean | false
    phoneNumber?: (e: any) => void
    confirmOtp?: boolean | false
    errorOtp?: string | ''
    verify?: (e: any) => void
    verifyConfirmOtp?: (e: any) => void
}

const initStateErrorMessage = {
    PHONE_NUMBER: ``,
};

export default function OtpComponant(props: otp) {
    const [loadingPage, setLoadingPage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
    const [otp, setOtp] = useState<string>("");
    const [refOtp, setRefOtp] = useState<string>("");
    const [errorOtp, setErrorOtp] = useState<string>(props.errorOtp || '');
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [confirmOtp, setConfirmOtp] = useState<boolean>(props.confirmOtp || false);
    const [countdown, setCountdown] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [checkPhoneNumber, setCheckPhonenumber] = useState<boolean>(false)
    const [checkDigiPhoneNumber, setCheckDigiPhonenumber] = useState<boolean>(false)
    const numbers = Array.from({ length: 6 }, (_, index) => index + 1);
    
    useEffect(() => {
        setConfirmOtp(props.confirmOtp || false)
        if (props.confirmOtp) {
            setPhoneNumber('')
            setOtp('')
        }
    }, [props.confirmOtp]);

    useEffect(() => {
        setErrorOtp(props.errorOtp || '')
    }, [props.errorOtp]);

    useEffect(() => {
        if (phoneNumber && phoneNumber.length === 12) {
            const check = checkPattenPhoneNumber(phoneNumber)
            setCheckPhonenumber(!check)
            setCheckDigiPhonenumber(false)
        }
    }, [phoneNumber]);

    useEffect(() => {
        let timer: any;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsButtonDisabled(false);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    const handleChangeOtp = (newValue: string) => {
        setOtp(newValue);
        setErrorOtp('')
    };

    useEffect(() => {
        if (otp.length === 6 && props.verify) {
            props.verify(otp)
        }
    }, [otp]);

    const requestOtp = async () => {
        if (checkPhoneNumber || checkDigiPhoneNumber || phoneNumber.length < 10) {
            const msg: any = {
                PHONE_NUMBER: !phoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber ? 'เบอร์โทรศัพท์ไม่ถูกต้อง' : phoneNumber.length < 10 ? 'ระบุจำนวน 10 ตัวอักษร' : '',
            }
            return setErrorMessage({ ...errorMessage, ...msg })
        }
        setIsButtonDisabled(false);
        try {
            const encryptPhoneNumber = encryptString(phoneNumber.replace(/-/g, ""));
            setLoadingPage(true);
            const res = await OtpApi.otpRequest({ tel: encryptPhoneNumber, page: props.register ? 'register' : 'login' });
            if (res.status === 200) {
                setRefOtp(res?.data?.data?.refId || '')
                setCountdown(30)
                setIsButtonDisabled(true);
                setConfirmOtp(true);
                props.verifyConfirmOtp && props.verifyConfirmOtp(true)
            }
            setLoadingPage(false);
        } catch (error: any) {
            setLoadingPage(false);
            swalError(String(error?.response.data.message));
            setOtp("");
        }
    };

    const requestOtpTimeout = async () => {
        try {
            const encryptPhoneNumber = encryptString(phoneNumber);
            const res = await OtpApi.otpRequest({ tel: encryptPhoneNumber, page: props.register ? 'register' : 'login' });
            if (res.status === 200) {
                setRefOtp(res?.data?.data?.refId || '')
                setIsButtonDisabled(true);
                setCountdown(30);
                setOtp("");
            }
        } catch (error: any) {
            swalError(String(error?.response.data.message));
            setIsButtonDisabled(false);
        }
    };

    return (
        <div>
            <Loading show={loadingPage}></Loading>
            {!confirmOtp && (
                <>
                    <div className="font-20 fw-bold">
                        <div className="mb-4">
                            <HeaderText label="รับรหัส OTP" component={<PhoneIcon />} enableBorder={true} />
                        </div>
                        <Typography variant="body2" className="mb-3">กรอกเบอร์โทรศัพท์ของคุณเพื่อรับรหัส OTP</Typography>
                        <InputTextField
                            value={phoneNumberFormat(phoneNumber)}
                            clearAble
                            size="medium"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            onClear={() => { setPhoneNumber('') }}
                            onchange={(e: any) => {
                                if (e.target.value.length <= 12) {
                                    setPhoneNumber(e.target.value);
                                    setErrorMessage({ ...errorMessage, PHONE_NUMBER: '' })
                                    if (props.phoneNumber) props.phoneNumber(e.target.value.replace(/-/g, ""))
                                }
                            }}
                            required
                            helperText={errorMessage.PHONE_NUMBER}
                            placeholder={"กรอกเบอร์โทรศัพท์มือถือ"}
                        />
                    </div>
                    <div className="pt-4">
                        <ButtonCustom
                            mode={"default"}
                            btnStyle={{ width: "100%" }}
                            textButton={"รับรหัส OTP"}
                            onClick={() => {
                                requestOtp();
                            }}
                            disabled={phoneNumber === ""}
                        />
                    </div>
                </>
            )}

            {confirmOtp && (
                <>
                    <div className="mb-4">
                        <HeaderText label="กรอกรหัส OTP" component={<LockIcon />} enableBorder={true} />
                    </div>
                    <Typography variant="body2" className="mb-5">เราได้ส่ง OTP ผ่านทาง SMS เบอร์โทรศัพท์ของคุณเรียบร้อยแล้ว</Typography>
                    <MuiOtpInput
                        TextFieldsProps={{
                            type: 'number',
                            inputProps: {
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            },
                        }}
                        value={otp || ""} length={6} onChange={handleChangeOtp}
                        sx={{
                            '& input': {
                                fontSize: '18px',
                            },
                            ".MuiOutlinedInput-input": {
                                zIndex: 1,
                            },
                            ...Object.fromEntries(
                                numbers.map((_: any, index: number) => [
                                    `& .MuiOtpInput-TextField-${index}`, // Dynamic class name
                                    {
                                        '& fieldset': {
                                            borderColor: otp.length >= index ? colors.themeMainColor : '',
                                            backgroundColor: otp.length >= index ? colors.pink_06 : '',// Conditional border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: otp.length >= index ? colors.themeMainColor : '',
                                            backgroundColor: otp.length >= index ? colors.pink_06 : '',// Conditional border color
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: otp.length >= index ? 'red' : '',
                                            backgroundColor: otp.length >= index ? colors.pink_06 : '',// Conditional border color
                                        },
                                    },
                                ])
                            ),

                        }}
                    />
                    <div className="error-otp">
                        <Typography color={colors.danger}>{errorOtp}</Typography>
                    </div>
                    <div className="f-14 request-otp" style={{ marginTop: '3vh' }}>

                        <div className="d-flex justify-content-between font-14">
                            <div>
                                Ref. OTP :  {refOtp || ''}
                            </div>
                            {isButtonDisabled ? (
                                <div className="otp-link-enabled">
                                    ส่งซ้ำ {countdown} วินาที
                                </div>
                            ) : (
                                <Typography color={colors.disabledGray} style={{ fontSize: 13 }}>
                                    ยังไม่ได้รับ OTP?
                                    <a
                                        className="otp-link"
                                        style={{ color: colors.themeMainColor, marginLeft: 5 }}
                                        onClick={(e) => requestOtpTimeout()}
                                    >
                                        ส่งอีกครั้ง
                                    </a>
                                </Typography>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
