import liff from "@line/liff";
import { useEffect } from "react";
import AuthenApi from "../../api/authen/authen.api";
import { imgs } from "../../constants/images";
import { logout } from "../../utils/app.utils";

export default function Logout() {
    useEffect(() => {
        liff.init({ liffId: '2006354132-Rz4a7axP', withLoginOnExternalBrowser: true })
            .then(async () => {
                if (liff.isLoggedIn()) {
                    await AuthenApi.logout()
                    logout()
                    liff.closeWindow()
                } else {
                    liff.login();
                    logout()
                    liff.closeWindow()
                }
            })
            .catch((err) => {
                logout()
                liff.closeWindow()
            })
    }, []);

    return (
        <div style={{}}>
            <img
                width={390}
                height={291}
                src={imgs.logOut}
                alt={'ออกจากระบบ'}
            />
        </div>
    )
}